import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fields: {
			"& .MuiFormControl-root": {
				maxWidth: 200,
				width: "100%",
				margin: theme.spacing(1),
			},
		},
	}),
);

export default useStyles;
