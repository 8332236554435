// MARK: React
import * as React from "react";
import API from "../../../../modules/API";
// Mark: Style

// MARK: Components
import SimpleTable from "../../../../components/SimpleTable";
import PageTableContainer from "../../../../components/PageTableContainer";

// MARK: Material
import {
	Button,
	Container,
	IconButton,
	TableCell,
	TableRow,
} from "@material-ui/core";

// MARK: Icons
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	Add as AddIcon,
} from "@material-ui/icons";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// Mark: Mobx
import { observer } from "mobx-react-lite";
import { useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";

// Mark Router
import { useHistory } from "react-router";

interface IProps {}

const List: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<>
			<PageTableContainer>
				<SimpleTable
					title={Strings.clientProjects.table.title}
					headers={Strings.clientProjects.table.header}
					pagination={store.pagination}
					rows={store.items}
					loading={store.loading}
					renderItem={(item) => (
						<TableRow hover={true} key={item.id}>
							<TableCell align={"center"}>{item.name}</TableCell>
							<TableCell align={"center"}>{item.clientId}</TableCell>
							<TableCell align={"center"}>
								<IconButton href={"/dashboard/clientProjects/edit/" + item.id}>
									<EditIcon />
								</IconButton>
							</TableCell>
							<TableCell align={"center"}>
								<IconButton onClick={() => store.openDeleteDialog(item)}>
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					)}
					bottomToolbar={
						// tslint:disable-next-line: jsx-wrap-multiline
						<Button color="secondary" href="/dashboard/clientProjects/create">
							<AddIcon />
							{Strings.clientProjects.table.createNewProject}
						</Button>
					}
				/>
			</PageTableContainer>
		</>
	);
};

export default observer(List);
