// MARK: React
import * as React from "react";

import useStyles from "./styles";

// MARK: Modulesd
import Strings from "../../../../modules/Strings";

// Mark: Component
import CardForm from "../../../../components/CardForm";

// Mark: Material
import { Button, CardContent, TextField } from "@material-ui/core";

// Mark: Router
import { useHistory, useParams } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";

const Edit: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<div className="home">
			<CardForm
				title={Strings.clients.edit.title}
				loading={store.loading}
				actionsFooter={(
					<Button
						variant="contained"
						onClick={() => {
							store.editClient(Strings.clients.edit.onClick);
						}}
					>
						{Strings.clients.edit.saveChanges}
					</Button>
				)}
			>
				<CardContent className={classes.fields}>
					<TextField
						{...store.formController.field("name")}
						label={Strings.fields.name}
					/>
					<TextField
						{...store.formController.field("phone")}
						label={Strings.fields.phone}
					/>
					<TextField
						{...store.formController.field("email")}
						label={Strings.fields.email}
					/>
					<TextField
						{...store.formController.field("document")}
						label={Strings.clients.fields.document}
					/>
				</CardContent>
			</CardForm>
		</div>
	);
};

export default observer(Edit);
