// MARK: API
import API from "../../modules/API";

// MARK: Mobx
import { observable, action, computed } from "mobx";

// MARK: Stores
import UIStore from "../UIStore";

// MARK: Resources
import FormStore from "../FormStore";
import Strings from "../../modules/Strings";

// MARK:  Modules
import { Errors } from "../../modules/Errors";

export default class AuthStore {

	private uiStore: UIStore;

	@observable public adminUser: API.AdminUser | null = null;

	@observable public loading: boolean = false;

	@observable public receivedToken: string = "";

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.updateUser();
	}

	public formController = new FormStore({
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	@computed
	public get isLoginFormReady() {
		const data = this.formController.getValues();
		return data.email.length > 5 && data.password.length >= 6;
	}

	@action
	private updateUser = () => {
		const adminUser = localStorage.getItem("adminUser");
		this.adminUser = adminUser ? JSON.parse(localStorage.getItem("adminUser") as string) : null;
	}

	@action
	public login = async (onSuccess?: () => void) => {
		const data = this.formController.getValues();
		this.loading = true;

		try {
			this.adminUser = await API.login(
				data.email.trim(),
				data.password.trim(),
			);

			localStorage.setItem("adminUser", JSON.stringify(this.adminUser));

			if (onSuccess) {
				onSuccess();
			}

			if (("Notification" in window)) {
				if (Notification.permission === "granted") {
					const notification = new Notification(Strings.hiAdmin);
				}
			}

			this.clear();
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e));
		} finally {
			this.loading = false;
		}
	};

	@action
	public logout = async (onSuccess?: () => void) => {
		this.uiStore.showDialog(
			Strings.logoutDialog,
			async () => {
				try {
					await API.logout();
					this.adminUser = null;
					localStorage.clear();
					if (onSuccess) {
						onSuccess();
					}
				} catch (e) {
					this.uiStore.showErrorSnackbar(e);
				}
			},
		);
	};

	@action
	public isLogged = async () => {
		this.loading = true;

		let adminUser: API.AdminUser | null = null;

		try {
			adminUser = await API.getCurrentAdminUser();
		} catch (e) {
			localStorage.clear();
		} finally {
			this.loading = false;
			this.adminUser = adminUser;
		}

		return !!this.adminUser;
	};

	@action
	public authenticate = async (onSuccess: (() => void) = () => {}, onFail: (() => void) = () => {}) => {
		if (!(await this.isLogged())) {
			onFail();
		} else {
			onSuccess();
		}
	};

	@action
	public setToken = (token: string) => {
		this.receivedToken = token;
	}

	@action
	private clear = () => {
		const data = this.formController.getValues();
		data.email = "";
		data.password = "";
	}
}
