import React from "react";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// Mark: Component
import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";
import { CardContent } from "@material-ui/core";
import FileCase from "../../../../components/FileCase";

// Mark: Material
import { Button, TextField } from "@material-ui/core";

// Mark: Router
import { useHistory, useParams } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";
import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	const formLabels = Strings.fields;
	const { field } = store.formController;
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.adminUsers.edit.title(store.model?.name)}
				loading={store.loading}
				actionsFooter={(
					<Button
						disabled={!!!store.adminUserImageService.getUploader()}
						variant="contained"
						onClick={() => {
							store.createAdminUser(Strings.adminUsers.edit.onClick);
						}}
					>
						{Strings.adminUsers.edit.saveChanges}
					</Button>
				)}
			>
				<CardContent className={classes.fields}>
					<TextField
						variant="standard"
						type="text"
						{...field("name")}
						label={formLabels.name}
					/>
					<TextField
						variant="standard"
						type="email"
						{...field("email")}
						label={formLabels.email}
					/>

					<FileCase
						title={formLabels.image}
						{...store.adminUserImageService.toCase()}
					/>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};

export default observer(EditorView);
