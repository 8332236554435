// MARK: React
import React from "react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Pages
import Table from "./TableView";

const ProjectPhases: React.FC = () => (
	<Switch>
		<Route exact={true} path="/dashboard/projectPhases" component={Table}/>
	</Switch>
);

export default observer(ProjectPhases);
