import { observable } from "mobx";

interface IConfigs<FormData> {
	labels?: Array<[keyof FormData, string]>;
}

export default class FormStore<FormData> {
	@observable private values: FormData;
	private configs: IConfigs<FormData> | null = null;

	constructor(initialValues: FormData, configs?: IConfigs<FormData>) {
		this.values = initialValues;
		this.configs = configs || null;
	}

	public handleHandle = (field: keyof FormData) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			this.values = {
				...this.values,
				[field]: e.target.value,
			};
		};
	};

	public field = (field: keyof FormData) => {
		return {
			onChange: this.handleHandle(field),
			value: this.values[field],
			label: this.configs?.labels?.find((label) => label[0] === field) || null,
		};
	};

	public getValues = () => {
		return this.values;
	};
}
