import React from "react";
import { useStyles } from "./style";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Libs
import moment from "moment";

// MARK: Modules
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";

// MARK: Store
import Store from "./store";

// MARK: Hooks
import { useGlobalStore } from "../../../context";
import { useHistory, useParams } from "react-router";

// MARK: Components
import { Box, Button, Typography, IconButton } from "@material-ui/core";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";

// MARK: Icon
import { WhatsApp as WhatsAppIcon, Call as CallIcon } from "@material-ui/icons";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {
	const { id } = useParams<IParams>();
	const { uiStore, themeStore } = useGlobalStore();
	const classes = useStyles(themeStore);
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore));
	const contactTitle = `${Strings.contacts.details.title} ${store.model?.name}`;
	const labels = Strings.contacts.details.header;

	return (
		<CardForm
			title={contactTitle}
			loading={store.loading}
			actionsFooter={(
				<Box className={classes.box}>
					<Button
						variant="contained"
						className={
							!!store.model?.archivedAt
								? classes.buttonUnarchive
								: classes.buttonSubmit
						}
						onClick={() =>
							store.archive(() => history.replace("/dashboard/contacts"))
						}
					>
						<Typography>
							{labels.isArchived(!!store.model?.archivedAt)}
						</Typography>
					</Button>
					<Box>
						{store.model && (
							<>
								<IconButton
									href={`tel:${store.model.phone}`}
									style={{ marginRight: 4 }}
								>
									<CallIcon />
								</IconButton>
								<IconButton
									className={classes.buttonWhats}
									target="_blank"
									href={`https://wa.me/${store.model.phone}/?text=Olá,%20Tudo%20Bem?`}
								>
									<WhatsAppIcon />
								</IconButton>
							</>
						)}
					</Box>
				</Box>
			)}
		>
			<DetailRow label={labels.name} value={store.model?.name} />
			<DetailRow
				label={labels.origin}
				value={
					store.model ? API.translateContactOrigin(store.model.origin) : ""
				}
			/>
			<DetailRow label={labels.email} value={store.model?.email} />
			<DetailRow
				label={labels.message}
				value={store.model?.message}
				multiline={true}
			/>
			<DetailRow label={labels.subject} value={store.model?.subject} />
			<DetailRow
				label={labels.createdDate}
				value={moment(store.model?.createdAt).format("DD/MM/YYYY")}
			/>
		</CardForm>
	);
};

export default observer(DetailsView);
