// MARK: React
import React from "react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Pages
import Table from "./TableView";
import Create from "./CreateView";
import Edit from "./EditView";
import View from "./DetailsView";

const Clients: React.FC = () => (
	<Switch>
		<Route exact={true} path="/dashboard/clients" component={Table} />
		<Route path="/dashboard/clients/create" component={Create} />
		<Route path="/dashboard/clients/edit/:id" component={Edit} />
		<Route path="/dashboard/clients/detail/:id" component={View} />
	</Switch>
);

export default observer(Clients);
