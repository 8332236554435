import { createMuiTheme } from "@material-ui/core/styles";

import { blue, grey, green, red } from "@material-ui/core/colors";

const defaultTheme = createMuiTheme({
	title: "defaultTheme",
	palette: {
		type: "light",
		primary: {
			light: grey[100],
			main: grey[900],
		},

		secondary: {
			light: blue[400],
			main: blue[500],
		},

		success: {
			light: green.A700,
			main: green.A700,
		},

		error: {
			light: red.A700,
			main: red.A700,
		},

		neutral: {
			light: grey[100],
			main: grey[900],
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},

	typography: {
		fontFamily: ["Roboto", "RobotoSlab"].join(","),
	},
});

export default defaultTheme;
