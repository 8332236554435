import React from "react";
import "./style.scss";

import { CardHeader, Divider, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { ArrowBack } from "@material-ui/icons";

interface IProps {
	onBackPress?: () => void;
	title: string;
}

export const BackButtonHeader = (props: IProps) => {
	const history = useHistory();

	return (
		<React.Fragment>
			<CardHeader
				className="rowWidth"
				title={<Typography variant={"h6"}>{props.title}</Typography>}
				avatar={
					(
						<IconButton
							onClick={
								props.onBackPress ? props.onBackPress : () => history.goBack()
							}
						>
							<ArrowBack />
						</IconButton>
					)
				}
			/>
			<Divider />
		</React.Fragment>
	);
};
