// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources

// MARK: Componentes
import { PageContainer } from "../PageContainer";
import {
	Box,
	Card,
	CardContent,
	CardActions,
	Divider,
	LinearProgress,
} from "@material-ui/core";
import { BackButtonHeader } from "../BackButtonHeader";

interface IProps {
	title: string;
	loading?: boolean;
	actionsFooter?: React.ReactElement | null;
}

const CardForm: React.FC<IProps> = (props) => {
	const { title, loading, actionsFooter } = props;
	return (
		<PageContainer>
			{loading ? <LinearProgress /> : null}
			<Card>
				<BackButtonHeader title={title} />
				<Box display="flex" flexDirection="column">
					<Divider />
					<CardContent className="FormColumn">{props.children}</CardContent>
				</Box>
				<Divider />
				<CardActions>
					{actionsFooter}
				</CardActions>
			</Card>
		</PageContainer>
	);
};

export default observer(CardForm);
