import { action, observable } from "mobx";
import API from "../../../../modules/API";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import FileStore from "../../../../stores/FileStore";

interface IPushAble {
	push: (path: string) => void;
	goBack: () => void;
}

export default class Store {
	@observable public loading: boolean = false;
	@observable public error: string | null = null;
	protected uiStore: UIStore;
	protected history: IPushAble;
	@observable public model: API.Client[];

	@observable public logoImageService: FileStore;
	@observable public projectPhase: API.ProjectPhase[];
	@observable public filesService: FileStore;
	@observable public id: string;
	@observable public clientId: string;
	@observable public phaseId: string;

	constructor(id: string, uiStore: UIStore, history: IPushAble) {
		this.fetchModel(id);
		this.id = id;
		this.uiStore = uiStore;
		this.history = history;
		this.filesService = new FileStore(uiStore, "file");

		this.logoImageService = new FileStore(uiStore, "image");
	}

	@action
	public setClientId = (id: string) => {
		this.clientId = id;
	};

	@action
	public setPhaseId = (id: string) => {
		this.phaseId = id;
	};
	@action
	private fetchModel = async (id: string) => {
		try {
			this.loading = true;
			const projectOldValues = await API.getClientProject(id);
			this.model = await API.getAllClients(0);
			this.projectPhase = await API.getAllProjectPhases(0);
			this.afterModelFetch(projectOldValues);
		} catch (e) {
			this.error = Errors.handleError(e);
		} finally {
			this.loading = false;
		}
	};
	public formController = new FormStore({
		name: "",
		description: "",
	});

	protected afterModelFetch(projectValues: API.ClientProject) {
		if (projectValues.logo) {
			this.logoImageService = new FileStore(
				this.uiStore,
				"image",
				projectValues.logo,
			);
		}
		this.filesService = new FileStore(
			this.uiStore,
			"file",
			projectValues.attachments,
		);
		if (projectValues.projectPhase?.id) {
			this.phaseId = projectValues.projectPhase.id;
		}
		this.clientId = projectValues.clientId;

		this.formController = new FormStore({
			name: projectValues.name || "",
			description: projectValues.description || "",
		});
	}

	@action
	public editClientProject = async (
		succesMessage: string,
		idClient: string,
		phaseId: string,
	) => {
		const data = this.formController.getValues();

		try {
			const logo = this.logoImageService.getUncertainfiedImage();
			const files = this.filesService.getUncertainfiedMultipleFiles();
			if (this.loading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}
			if (!!idClient === false) {
				throw {
					type: API.ErrorType,
					message: Strings.error.missingClient,
				};
			}
			if (!!phaseId === false) {
				throw {
					type: API.ErrorType,
					message: Strings.error.missingPhase,
				};
			}

			this.loading = true;
			this.error = null;

			await API.editClientProject(this.id, {
				name: data.name,
				logo: logo || null,
				description: data.description,
				attachments: files || [],
				clientId: idClient,
				projectPhaseId: phaseId,
			});

			this.uiStore.showSnackbar(succesMessage);
			this.history.goBack();
		} catch (err) {
			this.error = err.message;
			this.uiStore.showErrorSnackbar(this.error);
		} finally {
			this.loading = false;
		}
	};
}
