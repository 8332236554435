// MARK: React
import React, { useEffect } from "react";

// MARK: Components
import { MainLayout } from "../../layouts/MainLayout";
import CircularProgress from "../../components/CircularProgress";

// MARK: Stores
import { useGlobalStore } from "../context";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";

export const Auth = observer(() => {
	const { authStore } = useGlobalStore();
	const history = useHistory();

	useEffect(() => {
		authStore.authenticate(
			() => history.replace("/dashboard"),
			() => history.replace("/login"),
		);
	}, []);

	return (
		<MainLayout>
			<CircularProgress />
		</MainLayout>
	);
});
