import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => createStyles({
	card: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[600] : theme.palette.grey[200],
		borderRadius: 15,
	},
	content: {
		width: "100%",
	},
	singleLine: {
		display: "flex",
		justifyContent: "space-between",
	},
	multiline: {
		display: "flex",
		flexDirection: "column",
	},
}));
