// MARK: React
import React from "react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: View
import DetailsView from "./DetailsView";

// MARK: Table
import TableView from "./TableView";

const Contacts: React.FC = () => (
	<Switch>
		<Route exact={true} path="/dashboard/contacts" component={TableView} />
		<Route path="/dashboard/contacts/:id" component={DetailsView} />
	</Switch>
);

export default observer(Contacts);
