import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Layout
import { MainLayout } from "../../layouts/MainLayout";

// MARK: Components
import Typography from "@material-ui/core/Typography";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

// Resources
import Strings from "../../modules/Strings";

// MARK: Hooks
import { useHistory, useParams } from "react-router";

// MARK: Stores
import { useGlobalStore } from "../context";

export const Recovery = observer(() => {
	const { token } = useParams<{ token: string }>();
	const { authStore } = useGlobalStore();
	const history = useHistory();

	React.useEffect(() => {
		authStore.setToken(token);
	}, []);

	return (
		<MainLayout>
			{authStore.loading ? (
				<CircularProgress />
			) : (
				<div className="emailRecovery">
					<div className="emailRecoveryCard">
						<Typography className="loginTitle" variant="h5">
							{Strings.nav.title}
						</Typography>
						{authStore.receivedToken && authStore.receivedToken.length > 0 ? (
							<>
								<TextField
									{...authStore.formController.field("newPassword")}
									label={Strings.recoverPassword.newPassword}
									type="password"
									name="newPassword"
									disabled={authStore.loading}
								/>
								<TextField
									{...authStore.formController.field("confirmNewPassword")}
									label={Strings.recoverPassword.confirmNewPassword}
									type="password"
									name="confirmNewPassword"
									disabled={authStore.loading}
								/>
								{/*
												<Button className="emailRecoveryButton" variant="outlined" onClick={() => authStore.sendNewPassword()}>
													{Strings.recoverPassword.newPasswordButton}
												</Button>
											*/}
							</>
						) : (
							<>
								{/*
											<TextField
												label={Strings.recoverPassword.email}
												type="email"
												name="email"
												value={authStore.email}
												onChange={authStore.handleValueChange}
												disabled={authStore.loading}
											/>
											<Button className="emailRecoveryButton" variant="outlined" onClick={() => authStore.resetPassword()}>
												{Strings.recoverPassword.recoverPasswordButton}
											</Button>
										*/}
							</>
						)}
						<Button onClick={() => history.push("./login")}>
							{Strings.recoverPassword.backButton}
						</Button>
					</div>
				</div>
			)}
		</MainLayout>
	);
});
