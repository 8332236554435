// MARK: Mobx
import { observable, action, computed, runInAction } from "mobx";

// MARK: Modules
import API from "../modules/API";

// MARK: Interfaces
import { IRouterPusher } from "../interfaces/IRouter";

export default abstract class PaginatedListStore<ItemType> {
	private router: IRouterPusher;

	@observable private _loading: boolean = false;
	@observable private _page: number = 0;
	@observable private _items: ItemType[] = [];
	@observable private _error: string | null = null;

	constructor(router: IRouterPusher) {
		this.router = router;
	}

	@computed
	public get loading() {
		return this._loading;
	}

	@computed
	public get page() {
		return this._page;
	}

	@computed
	public get items() {
		return this._items;
	}

	protected abstract getDataItemsPerPage(page: number): Promise<ItemType[]>;

	@action
	public fetchPage = async (page: number) => {
		if (page < 0) {
			return;
		}

		if (this.loading) {
			throw {
				type: "StillLoading",
				message: "Ainda estamos buscando os dados, aguarde.",
			};
		}

		this._loading = true;

		try {
			const newActions: ItemType[] = await this.getDataItemsPerPage(page);
			if (newActions.length > 0) {
				runInAction(() => {
					this._items = newActions;
					this._page = page;
				});
			}
		} catch (e) {
			if (e.type && e.type === API.ErrorType.NotLoggedIn) {
				this.router.push("/");
			}

			this._error = e.message || "error";

			throw e;
		} finally {
			this._loading = false;
		}
	};

	@action
	public refresh = () => {
		this.fetchPage(this.page);
	};

	@action
	public clear = () => {
		this._items = [];
	}

	@action
	public applyFilter = (predicate: (item: ItemType, index: number, array: ItemType[]) => void) => {
		this._items = this._items.filter(predicate);
	}

	@action
	public nextPage = async () => await this.fetchPage(this._page + 1);

	@action
	public previousPage = async () => await this.fetchPage(this._page - 1);

	public get pagination() {
		return {
			page: this.page,
			onNextClick: this.nextPage,
			onPreviousClick: this.previousPage,
		};
	}

	@action
	public setLoading = (isLoading: boolean) => {
		this._loading = isLoading;
		return null;
	}
}
