// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// Mark: Pages
import List from "./ListView";
import Create from "./CreateView";
import Edit from "./EditView";

const ClientProjects = observer(() => {
	return (
		<Switch>
			<Route exact={true} path="/dashboard/clientProjects" component={List} />
			<Route path="/dashboard/clientProjects/create" component={Create} />
			<Route path="/dashboard/clientProjects/edit/:id" component={Edit} />
		</Switch>
	);
});
export default ClientProjects;
