import React from "react";

// MARK: style
import useStyles from "./styles";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Libs
import { useHistory } from "react-router-dom";

// MARK: Stores
import Store from "./store";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: components
import SimpleTable from "../../../../components/SimpleTable";
import {
	Button,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Chip,
	Typography,
} from "@material-ui/core";
import { Visibility, FilterList } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";

const TableView = () => {
	const classes = useStyles();
	const history = useHistory();
	const store = useLocalStore(() => new Store(history));
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const table = Strings.contacts.table;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		setAnchorEl(null);
		if	(event.currentTarget.value !== undefined) {
			if (event.currentTarget.value === -1) {
				store.setIsArchived(null);
			} else {
				store.setIsArchived(event.currentTarget.value);
			}
		}
	};

	const handleDelete = () => {
		store.setIsArchived(null);
	};
	return (
		<PageTableContainer>
			<SimpleTable
				loading={store.loading}
				title={Strings.contacts.title}
				headers={Strings.contacts.table.header}
				pagination={store.pagination}
				headerToolbar={(
					<>
						{!!store.currentFilter && (
							<Chip
								className={classes.chips}
								label={store.currentFilter}
								onDelete={handleDelete}
								color="default"
							/>
						)}
						<Button
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={handleClick}
						>
							{table.filter.title}
							<FilterList />
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted={true}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose} value={-1}>
								{table.filter.allContacts}
							</MenuItem>
							<MenuItem onClick={handleClose} value={1}>
								{table.filter.archived}
							</MenuItem>
							<MenuItem onClick={handleClose} value={0}>
								{table.filter.unarchived}
							</MenuItem>
						</Menu>
					</>
				)}
				rows={store.items}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.subject}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>
						{item.archivedAt ?
							<Typography className={classes.archived}>Sim</Typography>
								:
							<Typography className={classes.unarchived}>Não</Typography>
						}
						</TableCell>
						<TableCell align={"center"}>
							<IconButton href={"/dashboard/contacts/" + item.id}>
								<Visibility />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
