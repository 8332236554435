import React, { useState } from "react";
import { useStyles } from "./style";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Hooks
import { useIsMobile } from "../../hooks/useIsMobile";

// MARK: Components
import { Paper } from "@material-ui/core";
import { BaseLayout } from "../BaseLayout";
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import Navbar from "./Navbar";

export const MainLayout = observer((props) => {

	const classes = useStyles();
	const isMobile = useIsMobile();
	const [ open, setOpen ] = useState(false);

	return (
		<React.Fragment>
			<Navbar toggleDrawer={() => setOpen(!open)}/>
			{isMobile ? (
				<React.Fragment>
					<MobileDrawer
						open={open}
						setOpen={setOpen}
					/>
					<Paper className={classes.mainLayoutMobile}>
						{props.children}
					</Paper>
				</React.Fragment>
			) : (
				<React.Fragment>
					<DesktopDrawer open={open}/>
					<Paper
						className={(
							open && !isMobile
								? classes.mainLayoutContracted
								: classes.mainLayout
						)}
					>
						{props.children}
					</Paper>
				</React.Fragment>
			)}
			<BaseLayout />
		</React.Fragment>
	);
});

export default observer(MainLayout);
