import React from "react";
import { useStyles } from "./styles";

// MARK: Material
import { Box, Card, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface IProps {
	label?: string;
	value?: string | number;
	multiline?: boolean;
	loading?: boolean;
}

export const DetailRow = (props: IProps) => {
	const classes = useStyles();
	return (
		<Card elevation={0} className={classes.card}>
			{!props.loading ? (
				<Box
					className={([
						classes.content,
						classes.singleLine,
						props.multiline && classes.multiline,
					].join(" "))}
				>
					<Typography>{props.label}</Typography>
					<Typography>{props.value}</Typography>
				</Box>
			) : <Skeleton/>}
		</Card>
	);
};
