import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../modules/Strings";

// MARK: Store
import Store from "./store";
import { useGlobalStore } from "../../context";

// MARK: Components
import { DetailRow } from "../../../components/DetailRow";
import { PageContainer } from "../../../components/PageContainer";

// MARK: Material
import { Box } from "@material-ui/core";

const Home = observer(() => {

	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));

	return (
		<PageContainer>
			<Box display="flex" flexDirection="column">
				<DetailRow
					label={Strings.home.recentlyContacts}
					value={String(store.model?.recentContacts)}
					loading={store.loading}
				/>
				<DetailRow
					label={Strings.home.notArchivedContacts}
					value={String(store.model?.notArchivedContacts)}
					loading={store.loading}
				/>
				<DetailRow
					label={Strings.home.contactingWeekDayAverage}
					value={String(store.model?.contactingWeekDayAverage)}
					loading={store.loading}
				/>
			</Box>
		</PageContainer>
	);
});

export default Home;
