import React from "react";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Libs
import { useHistory } from "react-router-dom";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: components
import SimpleTable from "../../../../components/SimpleTable";
import PageTableContainer from "../../../../components/PageTableContainer";
import {
	TableCell,
	TableRow,
} from "@material-ui/core";

// MARK: Stores
import Store from "./store";

const TableView = () => {
	const history = useHistory();
	const store = useLocalStore(() => new Store(history));

	return (
		<PageTableContainer>
			<SimpleTable
				title={Strings.projectPhases.title}
				headers={Strings.projectPhases.table.header}
				pagination={store.pagination}
				loading={store.loading}
				rows={store.items}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.description}</TableCell>
						<TableCell align={"center"}>{item.color}</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
