import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { Button } from "@material-ui/core";
import { DetailRow } from "../../../../components/DetailRow";
import { Delete } from "@material-ui/icons";
import CardForm from "../../../../components/CardForm";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {

	const { id } = useParams<IParams>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	if (!store.model) {
		return null;
	}

	return (
		<CardForm
			title={Strings.admin.details.title}
			actionsFooter={(
				<Button
					startIcon={<Delete/>}
					onClick={store.showDeleteDialog}
				>
					{Strings.actions.delete}
				</Button>
			)}
		>
			<DetailRow label={Strings.fields.name} value={store.model.name}/>
			<DetailRow label={Strings.fields.email} value={store.model.email}/>
		</CardForm>
	);
};

export default observer(DetailsView);
