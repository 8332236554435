import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../modules/Strings";

// MARK: Icons
import { Menu } from "@material-ui/icons";

// MARK: Components
import { AvatarWithOptions } from "../AvatarWithOptions";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Detector } from "react-detect-offline";

interface IProps {
	toggleDrawer?: () => void;
}

const Navbar: React.FC<IProps> = (props) => {
	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					<IconButton
						color="secondary"
						edge="start"
						aria-label="Menu"
						onClick={props.toggleDrawer}
					>
						<Menu color="secondary" />
					</IconButton>
					<Typography color="secondary" variant="h6">
						{Strings.nav.title}
					</Typography>
					<div style={{ flexGrow: 1 }} />
					<AvatarWithOptions />
				</Toolbar>
				<Detector
				// tslint:disable-next-line: jsx-alignment
					render={({ online }) => !online && (
						<Alert style={{ borderRadius: 0 }} variant="filled" severity="warning">
							{Strings.nav.disconected}
						</Alert>
					)}
				/>
			</AppBar>
		</>
	);
};

export default observer(Navbar);
