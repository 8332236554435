import { action } from "mobx";
import API from "../../../../modules/API";
import FormStore from "../../../../stores/FormStore";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "src/stores/UIStore";

interface IPushAble {
	push: (path: string) => void;
}

export default class Store extends ModelStore<API.ClientDetails> {
	protected history: IPushAble;

	constructor(id: string, uiStore: UIStore, history: IPushAble) {
		super(id, uiStore);
		this.history = history;
	}
	public formController = new FormStore({
		name: "",
		phone: "",
		email: "",
		document: "",
	});

	protected getModel(id: string) {
		return API.getClient(id);
	}

	protected afterModelFetch(model: API.ClientDetails) {
		this.formController = new FormStore({
			name: model.name || "",
			phone: model.phone || "",
			email: model.email || "",
			document: model.document || "",
		});
	}

	@action
	public editClient = async (succesMessage: string) => {
		const data = this.formController.getValues();
		try {
			await API.editClient(this.id, {
				name: data.name,
				phone: data.phone,
				email: data.email,
				document: data.document,
			});
			this.uiStore.showSnackbar(succesMessage);
			this.history.push("/dashboard/clients");
		} catch (err) {
			this.error = err.message;
			this.uiStore.showSnackbar(err.message);
		}
	};
}
