import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import FileCase from "../../../../components/FileCase";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<CardForm
			title={Strings.admin.create.title}
			actionsFooter={(
				<Button
					disabled={store.loading}
					type="submit"
					variant="contained"
					color="default"
					onClick={store.createAdminUser}
				>
					{store.loading ? (
						<CircularProgress />
					) : (
						Strings.admin.create.createButton
					)}
				</Button>
			)}
		>
			<CardContent className={classes.fields}>
				<TextField
					variant="standard"
					type="text"
					{...store.formController.field("name")}
					label={Strings.fields.name}
				/>
				<TextField
					variant="standard"
					type="email"
					{...store.formController.field("email")}
					label={Strings.fields.email}
				/>
				<TextField
					variant="standard"
					type="password"
					{...store.formController.field("password")}
					label={Strings.fields.password}
				/>
				<FileCase
					title={Strings.fields.image}
					{...store.avatarController.toCase()}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
