import * as React from "react";
import "./style.scss";

// MARK: Material
import { Button as MButton } from "@material-ui/core";
import { ButtonProps as MButtonProps } from "@material-ui/core/Button";

interface IProps extends MButtonProps {
	loading?: boolean;
}

export default class Button extends React.Component<IProps> {
	public render() {
		const { className, loading, ...props } = this.props;

		return (
			<MButton
				{...props}
				className={className}
				disabled={this.props.loading || this.props.disabled}
			>
				{this.props.children}
			</MButton>
		);
	}
}
