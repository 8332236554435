import React from "react";

import useStyles from "./styles";

// MARK: Modulesd
import Strings from "../../../../modules/Strings";

// MARK: Material
import { Button, CardContent, TextField } from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";

// Mark: Router
import { useHistory } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";

const Create: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<div className="home">
			<CardForm
				title={Strings.clients.create.title}
				loading={store.loading}
				actionsFooter={(
					<Button
						variant="contained"
						color="primary"
						component="span"
						onClick={() => {
							store.createClient(Strings.clients.create.createClients);
						}}
					>
						{Strings.clients.create.title}
					</Button>
				)}
			>
				<CardContent className={classes.fields}>
					<TextField
						{...store.formController.field("name")}
						label={Strings.fields.name}
					/>
					<TextField
						{...store.formController.field("password")}
						label={Strings.fields.password}
					/>
					<TextField
						{...store.formController.field("phone")}
						label={Strings.fields.phone}
					/>
					<TextField
						{...store.formController.field("email")}
						label={Strings.fields.email}
					/>
					<TextField
						{...store.formController.field("document")}
						label={Strings.clients.fields.document}
					/>
				</CardContent>
			</CardForm>
		</div>
	);
};

export default observer(Create);
