import React from "react";
import { useStyles } from "./style";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Hooks
import { useMainRoutes } from "../../../hooks/useMainRoutes";
import { useHistory } from "react-router";

// MARK: Components
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core";

interface IProps {
	open: boolean;
}

const DesktopDrawer: React.FC<IProps> = (props) => {

	const routes = useMainRoutes();
	const classes = useStyles();
	const history = useHistory();

	return (
		<Drawer
			variant="permanent"
			className={classes.drawerButton}
			classes={{ paper: props.open ? classes.drawerOpen : classes.drawerClose }}
		>
			<Toolbar />
			<List>
				{routes.map((route) => {
					return (
						<ListItem
							key={route.text}
							onClick={() => history.push(route.path)}
							button={true}
						>
							<ListItemIcon>
								<route.Icon />
							</ListItemIcon>
							{props.open && (
								<ListItemText primary={route.text} />
							)}
						</ListItem>
					);
				})}
			</List>
		</Drawer>
	);
};

export default observer(DesktopDrawer);
