import { createStyles, makeStyles } from "@material-ui/core";
import { grey, blue, green, red } from "@material-ui/core/colors";

export const useStyles = (themeStore) => {
	return makeStyles(() =>
		createStyles({
			buttonSubmit: {
				"&:hover": {
					background: green.A400,
				},
				"background": themeStore.currentTheme.palette.success.main,
				"color": grey[50],
			},
			buttonArchive: {
				"&:hover": {
					background: blue[700],
				},
				"background": blue[900],
				"color": grey[50],
			},
			buttonUnarchive: {
				"&:hover": {
					background: red[700],
				},
				"background": themeStore.currentTheme.palette.error.main,
				"color": grey[50],
			},
			box: {
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
			},
			buttonWhats: {
				"&:hover": {
					background: green.A400,
				},
				"background": green.A400,
				"color": "#fff",
			},
		}),
	)();
};
