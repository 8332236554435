import React from "react";

// MARK: Components
import Snackbar from "../../components/Snackbar";
import CircularProgress from "../../components/CircularProgress";
import LinearProgress from "../../components/LinearProgress";
import SimpleDialog from "../../components/SimpleDialog";
import Alert from "../../components/Alert";

import { observer } from "mobx-react-lite";
import { useGlobalStore } from "../../pages/context";

export const BaseLayout = observer(() => {
	const { uiStore } = useGlobalStore();

	return (
		<>
			<LinearProgress hidden={!uiStore.linearLoading} />
			<CircularProgress hidden={!uiStore.circularLoading} />
			<Snackbar snackbarMessage={uiStore.snackbarMessage} />
			<SimpleDialog
				onDialogConfirm={uiStore.onDialogConfirm}
				clearDialog={uiStore.clearDialog}
				dialogMessage={uiStore.dialogMessage}
				dialogTitle={uiStore.dialogTitle}
			/>
			<Alert
				onAlertConfirm={uiStore.onAlertConfirm}
				clearAlert={uiStore.clearAlert}
				alertMessage={uiStore.alertMessage}
				alertTitle={uiStore.alertTitle}
			/>
		</>
	);
});
