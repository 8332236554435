// MARK: Stores
import PaginatedListStore from "../../../../stores/PaginatedListStore";

// MARK: Modules
import API from "../../../../modules/API";

// MARK: Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class Store extends PaginatedListStore<API.ProjectPhase> {

	constructor(router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(pageOffset: number) {
		return API.getAllProjectPhases(pageOffset);
	}

}
