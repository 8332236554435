// MARK: Stores
import PaginatedListStore from "../../../../stores/PaginatedListStore";

// MARK: Modules
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "src/stores/UIStore";
import { action, observable } from "mobx";

interface IPushAble {
	push: (path: string) => void;
}

export default class Store extends PaginatedListStore<API.ClientProject> {
	protected history: IPushAble;
	private uiStore: UIStore;

	constructor(uiStore: UIStore, history: IPushAble) {
		super(history);
		this.fetchPage(0);
		this.history = history;
		this.uiStore = uiStore;
	}
	protected getDataItemsPerPage(pageOffset: number) {
		return API.getClientProjects(pageOffset);
	}

	@action
	public openDeleteDialog = (project: API.ClientProject) => {
		this.uiStore.showDialog(
			{
				title: Strings.clientProjects.table.deleteTitle,
				message: project.name,
			},
			async () => {
				try {
					await API.deleteClientProject(project.id);
					this.uiStore.showSnackbar(Strings.portfolio.table.DeleteProject);
					this.refresh();
				} catch (e) {
					this.uiStore.showSnackbar(e.message);
				}
			},
		);
	};
}
