import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface File {
    name: string;
    url: string;
}

export interface Address {
    cep: string;
    number: string;
    street: string;
    neighborhood: string;
    city: string;
    cityId: string | null;
    state: string;
    uf: StateUF;
    latLng: LatLng;
}

export interface City {
    id: string;
    name: string;
    state: string;
    uf: string;
}

export interface Crop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
    crop: Crop | null;
}

export interface UncertainFile {
    fileData: UploadFile | null;
    file: File | null;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
    image: Image | null;
}

export interface NewAdminUser {
    password: string;
    image: UncertainImage | null;
    name: string;
    email: string;
}

export interface AdminUserDetails {
    name: string;
    email: string;
    image: Image | null;
}

export interface EditAdminUser {
    image: UncertainImage | null;
    name: string;
    email: string;
}

export interface BudgetRequest {
    id: string;
    design: string;
    availableBudget: AvaliableBudget;
    projectType: string;
    name: string;
    email: string;
    message: string;
}

export interface NewBudgetRequest {
    design: string;
    availableBudget: AvaliableBudget;
    projectType: string;
    name: string;
    email: string;
    message: string;
}

export interface BudgetRequestDetails {
    design: string;
    availableBudget: AvaliableBudget;
    projectType: string;
    name: string;
    email: string;
    message: string;
}

export interface AvaliableBudget {
    min: number | null;
    max: number | null;
}

export interface ClientDetails {
    name: string;
    phone: string;
    email: string;
    document: string;
}

export interface Client {
    id: string;
    name: string;
    phone: string;
    email: string;
    document: string;
}

export interface EditClient {
    name: string;
    phone: string;
    email: string;
    document: string;
}

export interface NewClient {
    password: string;
    name: string;
    phone: string;
    email: string;
    document: string;
}

export interface ProjectPhaseDetails {
    name: string;
    description: string;
    color: string;
}

export interface ProjectPhase {
    id: string;
    name: string;
    description: string;
    color: string;
}

export interface EditProjectPhase {
    name: string;
    description: string;
    color: string;
}

export interface NewProjectPhase {
    name: string;
    description: string;
    color: string;
}

export interface ClientProjectDetails {
    clientId: string;
    name: string;
    description: string;
}

export interface ClientProject {
    id: string;
    projectPhase: ProjectPhase | null;
    logo: Image | null;
    attachments: File[];
    clientId: string;
    name: string;
    description: string;
}

export interface EditClientProject {
    projectPhaseId: string;
    logo: UncertainImage | null;
    attachments: UncertainFile[];
    clientId: string;
    name: string;
    description: string;
}

export interface NewClientProject {
    projectPhaseId: string;
    logo: UncertainImage | null;
    attachments: UncertainFile[];
    clientId: string;
    name: string;
    description: string;
}

export interface ContactEmailDetails {
    email: string;
    name: string;
    phone: string;
    message: string;
}

export interface ContactEmail {
    id: string;
    email: string;
    name: string;
    phone: string;
    message: string;
}

export interface EditContactEmail {
    email: string;
    name: string;
    phone: string;
    message: string;
}

export interface NewContactEmail {
    email: string;
    name: string;
    phone: string;
    message: string;
}

export interface NewContact {
    name: string;
    email: string;
    phone: string;
    message: string;
}

export interface BudgetQuestion {
    title: string;
    description: string;
    awnser: BudgetQuestionAwnser;
}

export interface BudgetQuestionAwnser {
    text: string;
    value: number;
}

export interface GeneratedBudgetReportRequest {
    phone: string;
    name: string;
    email: string;
    questions: BudgetQuestion[];
}

export interface Contact {
    id: string;
    origin: ContactOrigin;
    email: string;
    name: string;
    message: string;
    subject: string;
    phone: string | null;
    createdAt: Date;
    archivedAt: Date | null;
}

export interface ContactFilter {
    isArchived: boolean | null;
}

export interface HomeData {
    notArchivedContacts: number;
    recentContacts: number;
    contactingWeekDayAverage: number;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum ImageFormat {
    png = "png",
    svg = "svg",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.svg: {
            return strings ? strings["enum"]["ImageFormat"]["svg"] || ImageFormat.svg : ImageFormat.svg;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.svg,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.svg),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ContactOrigin {
    calculator = "calculator",
    email = "email",
    budget = "budget",
}

export function translateContactOrigin(enumContactOrigin: ContactOrigin): string {
    switch (enumContactOrigin) {
        case ContactOrigin.calculator: {
            return strings ? strings["enum"]["ContactOrigin"]["calculator"] || ContactOrigin.calculator : ContactOrigin.calculator;
        }
        case ContactOrigin.email: {
            return strings ? strings["enum"]["ContactOrigin"]["email"] || ContactOrigin.email : ContactOrigin.email;
        }
        case ContactOrigin.budget: {
            return strings ? strings["enum"]["ContactOrigin"]["budget"] || ContactOrigin.budget : ContactOrigin.budget;
        }
    }
    return "";
}

export function allValuesContactOrigin(): ContactOrigin[] {
    return [
        ContactOrigin.calculator,
        ContactOrigin.email,
        ContactOrigin.budget,
    ];
}

export function allTranslatedValuesContactOrigin(): string[] {
    return [
        translateContactOrigin(ContactOrigin.calculator),
        translateContactOrigin(ContactOrigin.email),
        translateContactOrigin(ContactOrigin.budget),
    ];
}

export function allDisplayableValuesContactOrigin(): string[] {
    return allTranslatedValuesContactOrigin().sort();
}

export function valueFromTranslationContactOrigin(translation: string): ContactOrigin {
    const index = allTranslatedValuesContactOrigin().indexOf(translation);
    return allValuesContactOrigin()[index] || ContactOrigin.calculator;
}

export enum ErrorType {
    NotFound = "NotFound",
    InvalidArgument = "InvalidArgument",
    MissingArgument = "MissingArgument",
    MaximumExceded = "MaximumExceded",
    WrongLoginOrPassword = "WrongLoginOrPassword",
    NotLoggedIn = "NotLoggedIn",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    NickAlreadyInUse = "NickAlreadyInUse",
    InvalidNick = "InvalidNick",
    SlugAlreadyInUse = "SlugAlreadyInUse",
    InvalidSlug = "InvalidSlug",
    InvalidUrl = "InvalidUrl",
    InvalidColor = "InvalidColor",
    InvalidAction = "InvalidAction",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.MaximumExceded: {
            return strings ? strings["enum"]["ErrorType"]["MaximumExceded"] || ErrorType.MaximumExceded : ErrorType.MaximumExceded;
        }
        case ErrorType.WrongLoginOrPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongLoginOrPassword"] || ErrorType.WrongLoginOrPassword : ErrorType.WrongLoginOrPassword;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.NickAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyInUse"] || ErrorType.NickAlreadyInUse : ErrorType.NickAlreadyInUse;
        }
        case ErrorType.InvalidNick: {
            return strings ? strings["enum"]["ErrorType"]["InvalidNick"] || ErrorType.InvalidNick : ErrorType.InvalidNick;
        }
        case ErrorType.SlugAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["SlugAlreadyInUse"] || ErrorType.SlugAlreadyInUse : ErrorType.SlugAlreadyInUse;
        }
        case ErrorType.InvalidSlug: {
            return strings ? strings["enum"]["ErrorType"]["InvalidSlug"] || ErrorType.InvalidSlug : ErrorType.InvalidSlug;
        }
        case ErrorType.InvalidUrl: {
            return strings ? strings["enum"]["ErrorType"]["InvalidUrl"] || ErrorType.InvalidUrl : ErrorType.InvalidUrl;
        }
        case ErrorType.InvalidColor: {
            return strings ? strings["enum"]["ErrorType"]["InvalidColor"] || ErrorType.InvalidColor : ErrorType.InvalidColor;
        }
        case ErrorType.InvalidAction: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAction"] || ErrorType.InvalidAction : ErrorType.InvalidAction;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.InvalidArgument,
        ErrorType.MissingArgument,
        ErrorType.MaximumExceded,
        ErrorType.WrongLoginOrPassword,
        ErrorType.NotLoggedIn,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.NickAlreadyInUse,
        ErrorType.InvalidNick,
        ErrorType.SlugAlreadyInUse,
        ErrorType.InvalidSlug,
        ErrorType.InvalidUrl,
        ErrorType.InvalidColor,
        ErrorType.InvalidAction,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.MaximumExceded),
        translateErrorType(ErrorType.WrongLoginOrPassword),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.NickAlreadyInUse),
        translateErrorType(ErrorType.InvalidNick),
        translateErrorType(ErrorType.SlugAlreadyInUse),
        translateErrorType(ErrorType.InvalidSlug),
        translateErrorType(ErrorType.InvalidUrl),
        translateErrorType(ErrorType.InvalidColor),
        translateErrorType(ErrorType.InvalidAction),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, format: ImageFormat | null, crop: Crop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        format: format === null || format === undefined ? null : format,
        crop: crop === null || crop === undefined ? null : {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, crop: Crop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        crop: {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
    }));
}

export async function login(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "login", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            image: newAdminUser.image === null || newAdminUser.image === undefined ? null : {
                bytes: newAdminUser.image.bytes === null || newAdminUser.image.bytes === undefined ? null : newAdminUser.image.bytes.toString("base64"),
                image: newAdminUser.image.image === null || newAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: newAdminUser.image.image.thumb.width || 0,
                        height: newAdminUser.image.image.thumb.height || 0,
                        url: newAdminUser.image.image.thumb.url,
                    },
                    width: newAdminUser.image.image.width || 0,
                    height: newAdminUser.image.image.height || 0,
                    url: newAdminUser.image.image.url,
                },
                crop: newAdminUser.image.crop === null || newAdminUser.image.crop === undefined ? null : {
                    x: newAdminUser.image.crop.x || 0,
                    y: newAdminUser.image.crop.y || 0,
                    width: newAdminUser.image.crop.width || 0,
                    height: newAdminUser.image.crop.height || 0,
                },
            },
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function editAdminUser(id: string, editedAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editedAdminUser: {
            image: editedAdminUser.image === null || editedAdminUser.image === undefined ? null : {
                bytes: editedAdminUser.image.bytes === null || editedAdminUser.image.bytes === undefined ? null : editedAdminUser.image.bytes.toString("base64"),
                image: editedAdminUser.image.image === null || editedAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editedAdminUser.image.image.thumb.width || 0,
                        height: editedAdminUser.image.image.thumb.height || 0,
                        url: editedAdminUser.image.image.thumb.url,
                    },
                    width: editedAdminUser.image.image.width || 0,
                    height: editedAdminUser.image.image.height || 0,
                    url: editedAdminUser.image.image.url,
                },
                crop: editedAdminUser.image.crop === null || editedAdminUser.image.crop === undefined ? null : {
                    x: editedAdminUser.image.crop.x || 0,
                    y: editedAdminUser.image.crop.y || 0,
                    width: editedAdminUser.image.crop.width || 0,
                    height: editedAdminUser.image.crop.height || 0,
                },
            },
            name: editedAdminUser.name,
            email: editedAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function deleteAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "deleteAdminUser", args, progress});
    return undefined;
}

export async function createBudgetRequest(newBudgetRequest: NewBudgetRequest, progress?: (progress: number) => void): Promise<BudgetRequest> {
    const args = {
        newBudgetRequest: {
            design: newBudgetRequest.design,
            availableBudget: {
                min: newBudgetRequest.availableBudget.min === null || newBudgetRequest.availableBudget.min === undefined ? null : newBudgetRequest.availableBudget.min || 0,
                max: newBudgetRequest.availableBudget.max === null || newBudgetRequest.availableBudget.max === undefined ? null : newBudgetRequest.availableBudget.max || 0,
            },
            projectType: newBudgetRequest.projectType,
            name: newBudgetRequest.name,
            email: newBudgetRequest.email,
            message: newBudgetRequest.message,
        },
    };
    const ret = await makeRequest({name: "createBudgetRequest", args, progress});
    return {
        id: ret.id,
        design: ret.design,
        availableBudget: {
            min: ret.availableBudget.min === null || ret.availableBudget.min === undefined ? null : ret.availableBudget.min || 0,
            max: ret.availableBudget.max === null || ret.availableBudget.max === undefined ? null : ret.availableBudget.max || 0,
        },
        projectType: ret.projectType,
        name: ret.name,
        email: ret.email,
        message: ret.message,
    };
}

export async function getBudgetRequest(requestId: string, progress?: (progress: number) => void): Promise<BudgetRequest> {
    const args = {
        requestId: requestId,
    };
    const ret = await makeRequest({name: "getBudgetRequest", args, progress});
    return {
        id: ret.id,
        design: ret.design,
        availableBudget: {
            min: ret.availableBudget.min === null || ret.availableBudget.min === undefined ? null : ret.availableBudget.min || 0,
            max: ret.availableBudget.max === null || ret.availableBudget.max === undefined ? null : ret.availableBudget.max || 0,
        },
        projectType: ret.projectType,
        name: ret.name,
        email: ret.email,
        message: ret.message,
    };
}

export async function getBudgetRequests(pageOffset: number, progress?: (progress: number) => void): Promise<BudgetRequest[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getBudgetRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        design: e.design,
        availableBudget: {
            min: e.availableBudget.min === null || e.availableBudget.min === undefined ? null : e.availableBudget.min || 0,
            max: e.availableBudget.max === null || e.availableBudget.max === undefined ? null : e.availableBudget.max || 0,
        },
        projectType: e.projectType,
        name: e.name,
        email: e.email,
        message: e.message,
    }));
}

export async function deleteBudgetRequest(requestId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        requestId: requestId,
    };
    await makeRequest({name: "deleteBudgetRequest", args, progress});
    return undefined;
}

export async function getClient(id: string, progress?: (progress: number) => void): Promise<ClientDetails> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getClient", args, progress});
    return {
        name: ret.name,
        phone: ret.phone,
        email: ret.email,
        document: ret.document,
    };
}

export async function getAllClients(pageOffset: number, progress?: (progress: number) => void): Promise<Client[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClients", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        phone: e.phone,
        email: e.email,
        document: e.document,
    }));
}

export async function createClient(newClient: NewClient, progress?: (progress: number) => void): Promise<ClientDetails> {
    const args = {
        newClient: {
            password: newClient.password,
            name: newClient.name,
            phone: newClient.phone,
            email: newClient.email,
            document: newClient.document,
        },
    };
    const ret = await makeRequest({name: "createClient", args, progress});
    return {
        name: ret.name,
        phone: ret.phone,
        email: ret.email,
        document: ret.document,
    };
}

export async function editClient(clientId: string, editedClient: EditClient, progress?: (progress: number) => void): Promise<ClientDetails> {
    const args = {
        clientId: clientId,
        editedClient: {
            name: editedClient.name,
            phone: editedClient.phone,
            email: editedClient.email,
            document: editedClient.document,
        },
    };
    const ret = await makeRequest({name: "editClient", args, progress});
    return {
        name: ret.name,
        phone: ret.phone,
        email: ret.email,
        document: ret.document,
    };
}

export async function deleteClient(clientId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        clientId: clientId,
    };
    await makeRequest({name: "deleteClient", args, progress});
    return undefined;
}

export async function getProjectPhase(id: string, progress?: (progress: number) => void): Promise<ProjectPhaseDetails> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProjectPhase", args, progress});
    return {
        name: ret.name,
        description: ret.description,
        color: ret.color,
    };
}

export async function getAllProjectPhases(pageOffset: number, progress?: (progress: number) => void): Promise<ProjectPhase[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllProjectPhases", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        color: e.color,
    }));
}

export async function createProjectPhase(newProjectPhase: NewProjectPhase, progress?: (progress: number) => void): Promise<ProjectPhaseDetails> {
    const args = {
        newProjectPhase: {
            name: newProjectPhase.name,
            description: newProjectPhase.description,
            color: newProjectPhase.color,
        },
    };
    const ret = await makeRequest({name: "createProjectPhase", args, progress});
    return {
        name: ret.name,
        description: ret.description,
        color: ret.color,
    };
}

export async function editProjectPhase(projectPhaseId: string, editedProjectPhase: EditProjectPhase, progress?: (progress: number) => void): Promise<ProjectPhaseDetails> {
    const args = {
        projectPhaseId: projectPhaseId,
        editedProjectPhase: {
            name: editedProjectPhase.name,
            description: editedProjectPhase.description,
            color: editedProjectPhase.color,
        },
    };
    const ret = await makeRequest({name: "editProjectPhase", args, progress});
    return {
        name: ret.name,
        description: ret.description,
        color: ret.color,
    };
}

export async function deleteProjectPhase(projectPhaseId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        projectPhaseId: projectPhaseId,
    };
    await makeRequest({name: "deleteProjectPhase", args, progress});
    return undefined;
}

export async function getClientProject(clientProjectId: string, progress?: (progress: number) => void): Promise<ClientProject> {
    const args = {
        clientProjectId: clientProjectId,
    };
    const ret = await makeRequest({name: "getClientProject", args, progress});
    return {
        id: ret.id,
        projectPhase: ret.projectPhase === null || ret.projectPhase === undefined ? null : {
            id: ret.projectPhase.id,
            name: ret.projectPhase.name,
            description: ret.projectPhase.description,
            color: ret.projectPhase.color,
        },
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        attachments: ret.attachments.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        clientId: ret.clientId,
        name: ret.name,
        description: ret.description,
    };
}

export async function getClientProjects(pageOffset: number, progress?: (progress: number) => void): Promise<ClientProject[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getClientProjects", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        projectPhase: e.projectPhase === null || e.projectPhase === undefined ? null : {
            id: e.projectPhase.id,
            name: e.projectPhase.name,
            description: e.projectPhase.description,
            color: e.projectPhase.color,
        },
        logo: e.logo === null || e.logo === undefined ? null : {
            thumb: {
                width: e.logo.thumb.width || 0,
                height: e.logo.thumb.height || 0,
                url: e.logo.thumb.url,
            },
            width: e.logo.width || 0,
            height: e.logo.height || 0,
            url: e.logo.url,
        },
        attachments: e.attachments.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        clientId: e.clientId,
        name: e.name,
        description: e.description,
    }));
}

export async function getClientProjectByClient(pageOffset: number | null, clientId: string, progress?: (progress: number) => void): Promise<ClientProject[]> {
    const args = {
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
        clientId: clientId,
    };
    const ret = await makeRequest({name: "getClientProjectByClient", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        projectPhase: e.projectPhase === null || e.projectPhase === undefined ? null : {
            id: e.projectPhase.id,
            name: e.projectPhase.name,
            description: e.projectPhase.description,
            color: e.projectPhase.color,
        },
        logo: e.logo === null || e.logo === undefined ? null : {
            thumb: {
                width: e.logo.thumb.width || 0,
                height: e.logo.thumb.height || 0,
                url: e.logo.thumb.url,
            },
            width: e.logo.width || 0,
            height: e.logo.height || 0,
            url: e.logo.url,
        },
        attachments: e.attachments.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        clientId: e.clientId,
        name: e.name,
        description: e.description,
    }));
}

export async function createClientProject(newClientProject: NewClientProject, progress?: (progress: number) => void): Promise<ClientProject> {
    const args = {
        newClientProject: {
            projectPhaseId: newClientProject.projectPhaseId,
            logo: newClientProject.logo === null || newClientProject.logo === undefined ? null : {
                bytes: newClientProject.logo.bytes === null || newClientProject.logo.bytes === undefined ? null : newClientProject.logo.bytes.toString("base64"),
                image: newClientProject.logo.image === null || newClientProject.logo.image === undefined ? null : {
                    thumb: {
                        width: newClientProject.logo.image.thumb.width || 0,
                        height: newClientProject.logo.image.thumb.height || 0,
                        url: newClientProject.logo.image.thumb.url,
                    },
                    width: newClientProject.logo.image.width || 0,
                    height: newClientProject.logo.image.height || 0,
                    url: newClientProject.logo.image.url,
                },
                crop: newClientProject.logo.crop === null || newClientProject.logo.crop === undefined ? null : {
                    x: newClientProject.logo.crop.x || 0,
                    y: newClientProject.logo.crop.y || 0,
                    width: newClientProject.logo.crop.width || 0,
                    height: newClientProject.logo.crop.height || 0,
                },
            },
            attachments: newClientProject.attachments.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            clientId: newClientProject.clientId,
            name: newClientProject.name,
            description: newClientProject.description,
        },
    };
    const ret = await makeRequest({name: "createClientProject", args, progress});
    return {
        id: ret.id,
        projectPhase: ret.projectPhase === null || ret.projectPhase === undefined ? null : {
            id: ret.projectPhase.id,
            name: ret.projectPhase.name,
            description: ret.projectPhase.description,
            color: ret.projectPhase.color,
        },
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        attachments: ret.attachments.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        clientId: ret.clientId,
        name: ret.name,
        description: ret.description,
    };
}

export async function editClientProject(clientProjectId: string, editedClientProject: EditClientProject, progress?: (progress: number) => void): Promise<ClientProject> {
    const args = {
        clientProjectId: clientProjectId,
        editedClientProject: {
            projectPhaseId: editedClientProject.projectPhaseId,
            logo: editedClientProject.logo === null || editedClientProject.logo === undefined ? null : {
                bytes: editedClientProject.logo.bytes === null || editedClientProject.logo.bytes === undefined ? null : editedClientProject.logo.bytes.toString("base64"),
                image: editedClientProject.logo.image === null || editedClientProject.logo.image === undefined ? null : {
                    thumb: {
                        width: editedClientProject.logo.image.thumb.width || 0,
                        height: editedClientProject.logo.image.thumb.height || 0,
                        url: editedClientProject.logo.image.thumb.url,
                    },
                    width: editedClientProject.logo.image.width || 0,
                    height: editedClientProject.logo.image.height || 0,
                    url: editedClientProject.logo.image.url,
                },
                crop: editedClientProject.logo.crop === null || editedClientProject.logo.crop === undefined ? null : {
                    x: editedClientProject.logo.crop.x || 0,
                    y: editedClientProject.logo.crop.y || 0,
                    width: editedClientProject.logo.crop.width || 0,
                    height: editedClientProject.logo.crop.height || 0,
                },
            },
            attachments: editedClientProject.attachments.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            clientId: editedClientProject.clientId,
            name: editedClientProject.name,
            description: editedClientProject.description,
        },
    };
    const ret = await makeRequest({name: "editClientProject", args, progress});
    return {
        id: ret.id,
        projectPhase: ret.projectPhase === null || ret.projectPhase === undefined ? null : {
            id: ret.projectPhase.id,
            name: ret.projectPhase.name,
            description: ret.projectPhase.description,
            color: ret.projectPhase.color,
        },
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        attachments: ret.attachments.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        clientId: ret.clientId,
        name: ret.name,
        description: ret.description,
    };
}

export async function deleteClientProject(clientProjectId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        clientProjectId: clientProjectId,
    };
    await makeRequest({name: "deleteClientProject", args, progress});
    return undefined;
}

export async function getContactEmail(id: string, progress?: (progress: number) => void): Promise<ContactEmailDetails> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getContactEmail", args, progress});
    return {
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        message: ret.message,
    };
}

export async function getAllContactsEmails(pageOffset: number, progress?: (progress: number) => void): Promise<ContactEmail[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllContactsEmails", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        phone: e.phone,
        message: e.message,
    }));
}

export async function createContactEmail(newContact: NewContactEmail, progress?: (progress: number) => void): Promise<ContactEmailDetails> {
    const args = {
        newContact: {
            email: newContact.email,
            name: newContact.name,
            phone: newContact.phone,
            message: newContact.message,
        },
    };
    const ret = await makeRequest({name: "createContactEmail", args, progress});
    return {
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        message: ret.message,
    };
}

export async function editContactEmail(contactId: string, editedContact: EditContactEmail, progress?: (progress: number) => void): Promise<ContactEmailDetails> {
    const args = {
        contactId: contactId,
        editedContact: {
            email: editedContact.email,
            name: editedContact.name,
            phone: editedContact.phone,
            message: editedContact.message,
        },
    };
    const ret = await makeRequest({name: "editContactEmail", args, progress});
    return {
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        message: ret.message,
    };
}

export async function deleteContactEmail(contactId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        contactId: contactId,
    };
    await makeRequest({name: "deleteContactEmail", args, progress});
    return undefined;
}

export async function sendContact(newContact: NewContact, progress?: (progress: number) => void): Promise<void> {
    const args = {
        newContact: {
            name: newContact.name,
            email: newContact.email,
            phone: newContact.phone,
            message: newContact.message,
        },
    };
    await makeRequest({name: "sendContact", args, progress});
    return undefined;
}

export async function generateBudgetToEmail(request: GeneratedBudgetReportRequest, progress?: (progress: number) => void): Promise<void> {
    const args = {
        request: {
            phone: request.phone,
            name: request.name,
            email: request.email,
            questions: request.questions.map(e => ({
                title: e.title,
                description: e.description,
                awnser: {
                    text: e.awnser.text,
                    value: e.awnser.value || 0,
                },
            })),
        },
    };
    await makeRequest({name: "generateBudgetToEmail", args, progress});
    return undefined;
}

export async function getCalculatorGeneratedBudgets(pageOffset: number, progress?: (progress: number) => void): Promise<GeneratedBudgetReportRequest[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCalculatorGeneratedBudgets", args, progress});
    return ret.map((e: any) => ({
        phone: e.phone,
        name: e.name,
        email: e.email,
        questions: e.questions.map((e: any) => ({
            title: e.title,
            description: e.description,
            awnser: {
                text: e.awnser.text,
                value: e.awnser.value || 0,
            },
        })),
    }));
}

export async function getCalculatorGeneratedBudget(id: string, progress?: (progress: number) => void): Promise<GeneratedBudgetReportRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getCalculatorGeneratedBudget", args, progress});
    return {
        phone: ret.phone,
        name: ret.name,
        email: ret.email,
        questions: ret.questions.map((e: any) => ({
            title: e.title,
            description: e.description,
            awnser: {
                text: e.awnser.text,
                value: e.awnser.value || 0,
            },
        })),
    };
}

export async function getAllContacts(pageOffset: number, filter: ContactFilter, progress?: (progress: number) => void): Promise<Contact[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            isArchived: filter.isArchived === null || filter.isArchived === undefined ? null : !!filter.isArchived,
        },
    };
    const ret = await makeRequest({name: "getAllContacts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        origin: e.origin,
        email: e.email,
        name: e.name,
        message: e.message,
        subject: e.subject,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        createdAt: new Date(e.createdAt + "Z"),
        archivedAt: e.archivedAt === null || e.archivedAt === undefined ? null : new Date(e.archivedAt + "Z"),
    }));
}

export async function getContact(contactId: string, progress?: (progress: number) => void): Promise<Contact> {
    const args = {
        contactId: contactId,
    };
    const ret = await makeRequest({name: "getContact", args, progress});
    return {
        id: ret.id,
        origin: ret.origin,
        email: ret.email,
        name: ret.name,
        message: ret.message,
        subject: ret.subject,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        archivedAt: ret.archivedAt === null || ret.archivedAt === undefined ? null : new Date(ret.archivedAt + "Z"),
    };
}

export async function archiveContact(contactId: string, archive: boolean, progress?: (progress: number) => void): Promise<Contact> {
    const args = {
        contactId: contactId,
        archive: !!archive,
    };
    const ret = await makeRequest({name: "archiveContact", args, progress});
    return {
        id: ret.id,
        origin: ret.origin,
        email: ret.email,
        name: ret.name,
        message: ret.message,
        subject: ret.subject,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        archivedAt: ret.archivedAt === null || ret.archivedAt === undefined ? null : new Date(ret.archivedAt + "Z"),
    };
}

export async function getHomeData(progress?: (progress: number) => void): Promise<HomeData> {
    const ret = await makeRequest({name: "getHomeData", args: {}, progress});
    return {
        notArchivedContacts: ret.notArchivedContacts || 0,
        recentContacts: ret.recentContacts || 0,
        contactingWeekDayAverage: ret.contactingWeekDayAverage || 0,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
