// MARK: Mobx
import { action, observable } from "mobx";

// MARK: Modules
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";

// MARK: Store
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";

// MARK: Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class TableStore extends PaginatedListStore<API.ClientProject> {
	private uiStore: UIStore;
	@observable public clientId: string;

	constructor(clintId: string, uiStore: UIStore, router: IRouterPusher) {
		super(router);

		this.uiStore = uiStore;
		this.clientId = clintId;
		this.fetchPage(0);
	}

	@action.bound
	protected getDataItemsPerPage(pageOffSet: number) {
		return API.getClientProjectByClient(pageOffSet, this.clientId);
	}

	@action
	public openDeleteDialog = (project: API.ClientProject) => {
		this.uiStore.showDialog(
			{
				title: Strings.clients.details.table.deleteTitle,
				message: project.name,
			},
			async () => {
				try {
					await API.deleteClientProject(project.id);
					this.uiStore.showSnackbar(
						Strings.clients.details.table.deletedProject,
					);
					this.refresh();
				} catch (e) {
					this.uiStore.showSnackbar(e.message);
				}
			},
		);
	};
}
