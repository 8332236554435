// MARK: Stores
import PaginatedListStore from "../../../../stores/PaginatedListStore";

// MARK: Mobx
import { observable, action } from "mobx";

// MARK: Modules
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";

// MARK: Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class Store extends PaginatedListStore<API.Contact> {
	@observable public isArchived: boolean | null = null;
	@observable public currentFilter: string | null = null;

	public filter = {
		0: Strings.contacts.table.filter.unarchived,
		1: Strings.contacts.table.filter.archived,
	};

	constructor(router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
	}

	@action
	public setIsArchived(selected: number | null) {
		this.currentFilter = selected !== null ? this.filter[selected] : null;
		this.isArchived = !!selected;
		this.clear();
		this.refresh();
	}

	protected getDataItemsPerPage(pageOffset: number) {
		return API.getAllContacts(pageOffset, { isArchived: this.isArchived });
	}
}
