import LocalizedStrings from "localized-strings";
import API from "./API";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			delete: "Apagar",
			create: "Criar",
		},
		fields: {
			archive: "Arquivar",
			type: "Tipo",
			url: "Url",
			id: "Id",
			image: "Imagem",
			images: "Imagens",
			phone: "Telefone",
			whatsapp: "Whatsapp",
			name: "Nome",
			budgetRate: "Média de orçamento",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			agencyDV: "Dígito da Agência",
			agency: "Agência",
			bank: "Banco",
			account: "Conta",
			accountDV: "Dígito da Conta",
			text: "Texto",
			design: "Design",
			typeProject: "Tipo de projeto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			deadlineDate: "Data de Prazo",
			startDate: "Iniciado em",
			user: "Usuário",
		},
		dialogs: {
			deleteItem: {
				title: "Apagar Item",
				message: "Tem certeza que deseja apagar esse item?",
			},
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message: "Tem certeza que deseja apagar o contato " + name + " ?, essa operação não pode ser revertida.",
			}),
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		loading: "Carregando",
		login: "Login",
		hiAdmin: "Olá, Administrador(a)",
		recoverPassword: {
			confimationDialog: {
				title: "Recuperação de Senha",
				message:
					"Caso esse email pertença a um administrador, o link de recuperação de senha foi enviado com sucesso",
			},
			recoverPasswordButton: "Recuperar Senha",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
		},
		components: {
			table: {
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				detailsAndEditAndDelete: "Editar / Detalhes / Delete",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		languages: {
			title: "Idiomas",
			ptBr: "Português",
			enUs: "Inglês",
		},
		admin: {
			table: {
				title: "Tabela de administradores",
			},
			details: {
				title: "Detalhes do administrador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logo: {
			alt: "Logo Alt Aqui!",
			squared: "/logo_squared.png",
			horizontalWhite: "/logo_horizontal_white.png",
		},
		error: {
			needLongerText: (numOfChars: number) =>
				"Precisa ser maior que " + numOfChars + " caracteres.",
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingClient: "É Necessário Selecionar Um Cliente!",
			missingPhase: "É Necessário Selecionar A Fase Do Projeto",
			clientNotFound: (id?: string) => `Cliente ${id && "[" + id + "] "}não encontrado.`,
		},
		nav: {
			title: "Startapp Admin",
			home: "Inicio",
			contacts: "Contatos",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			clients: "Clientes",
			clientProjects: "Projetos De Clientes",
			projectPhases: "Fases de Projeto",
			disconected: "Você está descontectado da internet",
		},
		budget: {
			budgetTableTitle: "Tabela de orçamentos",
			budgetTableHeader: [
				"Nome",
				"email",
				"Design",
				"Tipo de projeto",
				"Visualizar",
			],
		},
		budgetDetails: {
			title: "Detalhes do orçamento",
			messageEmail: "Envie uma mensagem para",
			textButton: "Enviar",
		},
		home: {
			welcome: "Bem vindo ao dashboard da Startapp New",
			currentlyInformations: "Informações Atuais",
			recentlyContacts: "Contatos Recentes",
			notArchivedContacts: "Contatos não Arquivados",
			contactingWeekDayAverage: "Média de Contatos / dia da semana",
		},
		contacts: {
			title: "Contatos",
			table: {
				header: ["Nome", "Email", "Assunto", "Arquivado", "Visualizar"],
				filter: {
					title: "Filtro",
					allContacts: "Todos",
					archived: "Arquivados",
					unarchived: "Desarquivados",
				},
				yes: "Sim",
				no: "Não",
			},
			details: {
				title: "Contato de",
				header: {
					name: "Nome",
					origin: "Origem",
					email: "Email",
					message: "Mensagem",
					subject: "Assunto",
					createdDate: "Data de criação",
					isArchived: (archive: boolean) =>
						archive ? "Desarquivar" : "Arquivar",
				},
			},
		},
		contactsView: {
			title: "Contato de",
			modalTitle: "Deseja excluir o contato abaixo?",
			header: {
				name: "Nome",
				subject: "Assunto",
				email: "Email",
				view: "Vizualizar",
				message: "Mensagem",
			},
			button: {
				delete: "Apagar",
				cancel: "Cancelar",
			},
		},
		clients: {
			title: "Clientes",
			table: {
				header: [
					"Nome",
					"Telefone",
					"Email",
					"Documento",
					"Vizualizar",
					"Editar",
					"Deletar",
				],
				createNewClient: "Criar",
				deleteClient: "Cliente Deletado!",
			},
			create: {
				onClick: "Cliente Criado!",
				title: "Criando Cliente",
				createClients: "Criar Cliente",
			},
			edit: {
				title: "Edição De Clientes",
				onClick: "Cliente Foi Editado!",
				saveChanges: "Salvar Alterações",
			},
			fields: {
				document: "Documento",
			},
			details: {
				title: "Cliente:",
				header: { name: "Nome", email: "Email", phone: "Telefone" },
				table: {
					title: "Projetos De ",
					header: ["Nome", "Editar", "Deletar"],
					deletedProject: "Projeto Deletado",
					deleteTitle: "Deseja Excluir? ",
				},
			},
		},
		projectPhases: {
			title: "Fases de Projeto",
			table: {
				header: ["Nome", "Descrição", "Cor"],
			},
		},
		portfolio: {
			table: {
				title: "Projetos Realizados",
				header: ["Projetos", "Editar", "Deletar"],
				createNewProject: "Criar",
				DeleteProject: "Projeto Deletado!",
				deleteTitle: "Deseja Excluir?",
			},
			edit: {
				title: (projectName?: string) => "Edição " + projectName,
				onClick: "Projeto Foi Editado",
				saveChanges: "Salvar",
			},
			create: {
				onClick: "Projeto Criado",
				title: "Criando Projeto",
				createProject: "Criar",
				success: (projectName: string) => `${projectName} criado com sucesso`,
			},
			fields: {
				projectName: "Nome do Projeto",
				image: "URL Imagem",
				description: "Descrição",
				logo: "URL Logo",
				siteUrl: "URL Do Site",
				googleStore: "Link GoogleStore",
				appStore: "Link AppSore",
				aboutImage: "AboutImagem",
				slug: "Slug",
				aboutText: "Sobre",
				color: "Cor",
			},
		},
		clientProjects: {
			table: {
				title: "Projetos Dos Clientes",
				header: ["Projetos", "Clientes", "Editar", "Deletar"],
				createNewProject: "Criar",
				deletedProject: "Projeto Deletado!",
				deleteTitle: "Deseja Excluir?",
			},
			edit: {
				title: "Edição De Projetos",
				editedProject: "Projeto Editado",
				editButton: "Salvar",
				fields: {
					name: "Nome",
					clients: "Clientes",
					logo: "Logo",
					discription: "Descrição",
					attachments: "Anexos",
					crateProject: "Criar",
				},
			},
			create: {
				title: "Criar Projetos Para Clientes",
				createdProject: "Projeto",
				createdProject2: "Criado",
				fields: {
					name: "Nome",
					clients: "Clientes",
					logo: "Logo",
					discription: "Descrição",
					client: "Cliente",
					phase: "Fase",
					attachments: "Anexos",
					crateProject: "Criar",
				},
			},
		},
		message: {
			noMoreResults: "Sem mais resultados",
			clientWasDeleted: "Cliente foi apagado com sucesso.",
		},
		buttons: {
			edit: "Editar",
			save: "Salvar",
			filter: "Filtrar",
			close: "Fechar",
			search: "Procurar",
		},
		filter: {
			title: "Filtro",
			dateStart: "Inicio",
			dateEnd: "Fim",
			addFilter: "Adicionar um filtro",
			statusFilterTitle: "Status",
			driversFilterTitle: "Filtrar Motoristas",
			nameFilterTitle: "Nome",
			emailFilterTitle: "Email",
			phoneFilterTitle: "Telefone",
			cpfFilterTitle: "CPF",
			textField: {
				apply: "Aplicar",
				add: "Adicionar",
				clear: "Limpar",
			},
			autosuggest: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			components: {
				filterText: {
					addText: "Adicionar",
				},
			},
			enumPicker: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			badges: {
				statusActive: (value: boolean | null) =>
					`${value === false ? "Status: Inativo" : "Status: Ativo"}`,
				driverActive: (value: boolean | null) =>
					`${value === false ? "Motorista: Inativo" : "Motorista: Ativo"}`,
				name: (text: string) => `Nome: ${text}`,
				phone: (text: string) => `Telefone: ${text}`,
				cpf: (text: string) => `CPF: ${text}`,
				email: (text: string) => `Email: ${text}`,
			},
			filter: "Filtrar",
		},
		register: {
			title: "Seus Dados",
			documentNumber: "CPF",
			phone: "Telefone",
			permissions: "Permissões",
			payment: {
				title: "Pagamento",
				cardNumber: "Número do Cartão",
				holderName: "Nome do Titular",
				cvv: "Código de Segurança",
				expirationDate: "Data de Validade",
				plans: "Planos",
			},
			company: "Sua Empresa",
			create: "Criar Conta",
			back: "Voltar",
			next: "Continuar",
			finish: "Concluir",
			finishCredit: "Concluir Crédito",
			resumeCredit: "Retomar Crédito",
			creditCompletedSucess: "Crédito concluído com sucesso",
			creditLogSucess: "Aviso concluído com sucesso",
			creditResumeSucess: "Crédito retomado com sucesso",
			createCredit: "Criar crédito",
			registered: "Conta criada com sucesso",
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successCredit: "Crédito criado com sucesso",
			edited: "Administrador editado com sucesso",
			editedUser: "Usúario editado com suscesso",
		},

		edit: {
			onSuccessEdit: "Usuário Editado com sucesso",
		},
		adminUsers: {
			title: "Administradores",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode ? "Editar Administrador" : "Cadastrar Administrador",
			},
			edit: {
				title: (adminUserName?: string) => "Edição " + adminUserName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (adminUserName: string) =>
					`${adminUserName} modificado com sucesso!`,
			},
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		enum: {
			Language: {
				ptBr: "Português",
			},
			ContactOrigin: {
				calculator: "Calculadora",
				email: "Email",
				budget: "Orçamento",
			},
		},
	},
});

API.setStrings(Strings);
export default Strings;
