import {
	ContactMail,
	FolderOpen,
	Home,
	People,
	Person,
	AttachFile,
	Description,
} from "@material-ui/icons";
import Strings from "../modules/Strings";

export function useMainRoutes() {
	return [
		{
			Icon: Home,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: ContactMail,
			text: Strings.nav.contacts,
			path: "/dashboard/contacts",
		},
		{
			Icon: Person,
			text: Strings.nav.adminUsers,
			path: "/dashboard/adminUsers",
		},
		{
			Icon: People,
			text: Strings.nav.clients,
			path: "/dashboard/clients",
		},
		{
			Icon: AttachFile,
			text: Strings.nav.clientProjects,
			path: "/dashboard/ClientProjects",
		},
		{
			Icon: Description,
			text: Strings.nav.projectPhases,
			path: "/dashboard/projectPhases",
		},
	];
}
