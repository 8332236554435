// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Component
import SimpleTable from "../../../../components/SimpleTable";
import PageTableContainer from "../../../../components/PageTableContainer";
import { DetailRow } from "../../../../components/DetailRow";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Store
import { useGlobalStore } from "../../../context";
import { useParams } from "react-router";
import ViewStore from "./viewStore";
import TableStore from "./tableStore";

// MARK: Material
import {
	Button,
	IconButton,
	LinearProgress,
	TableCell,
	TableRow,
	CardContent,
	Box,
	Card,
	Divider,
} from "@material-ui/core";

// MARK: Material-Icons
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	Add as AddIcon,
	WhatsApp as WhatsAppIcon,
	Call as CallIcon,
} from "@material-ui/icons";
// MARK: Components
import { BackButtonHeader } from "../../../../components/BackButtonHeader";
import { PageContainer } from "../../../../components/PageContainer";

// MARK: Router
import { useHistory } from "react-router-dom";

const View: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const viewStore = useLocalStore(() => new ViewStore(id, uiStore));
	const projects = useLocalStore(() => new TableStore(id, uiStore, history));

	return (
		<>
			{viewStore.model && (
				<>
					<PageContainer>
						<Card>
							<BackButtonHeader
								title={
									Strings.clients.details.title + " " + viewStore.model.name
								}
							/>
							<Box display="flex" flexDirection="column">
								<Divider />

								{viewStore.loading ? <LinearProgress /> : null}
								<CardContent>
									<DetailRow
										label={Strings.clients.details.header.name}
										value={viewStore.model.name}
									/>
									<DetailRow
										label={Strings.clients.details.header.email}
										value={viewStore.model.email}
									/>
									<DetailRow
										label={Strings.clients.details.header.phone}
										value={viewStore.model.phone}
									/>
									<Box
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-end",
										}}
									>
										<>
											<IconButton
												href={`tel:${viewStore.model.phone}`}
												style={{
													marginRight: 4,
												}}
											>
												<CallIcon />
											</IconButton>
											<IconButton
												style={{
													backgroundColor: "#00e676",
													color: "#fafafa",
												}}
												target="_blank"
												href={`https://wa.me/${viewStore.model.phone}/?text=Olá,%20Tudo%20Bem?`}
											>
												<WhatsAppIcon />
											</IconButton>
										</>
									</Box>
								</CardContent>
							</Box>
							<Divider />
						</Card>

						<PageTableContainer>
							<SimpleTable
								title={
									Strings.clients.details.table.title + viewStore.model?.name
								}
								loading={projects.loading}
								headers={Strings.clients.details.table.header}
								rows={projects.items}
								pagination={projects.pagination}
								renderItem={(item) => (
									<TableRow key={item.id}>
										<TableCell align={"center"}>{item.name}</TableCell>
										<TableCell align={"center"}>
											<IconButton
												href={"/dashboard/clientProjects/edit/" + item.id}
											>
												<EditIcon />
											</IconButton>
										</TableCell>
										<TableCell align={"center"}>
											<IconButton
												onClick={() => {
													projects.openDeleteDialog(item);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								)}
								bottomToolbar={
									// tslint:disable-next-line: jsx-wrap-multiline
									<Button
										color="secondary"
										href="/dashboard/clientProjects/create"
									>
										<AddIcon />
										{Strings.clientProjects.table.createNewProject}
									</Button>
								}
							/>
						</PageTableContainer>
					</PageContainer>
				</>
			)}
		</>
	);
};

export default observer(View);
