import { action, observable } from "mobx";
import { Errors } from "../modules/Errors";
import UIStore from "../stores/UIStore";

export default abstract class ModelStore<ModelType, IdType = string> {
	@observable public model: ModelType | null = null;
	@observable public loading: boolean = false;
	@observable public error: string | null = null;
	@observable protected id: IdType;

	protected uiStore: UIStore;

	constructor(id: IdType, uiStore: UIStore) {
		this.fetchModel(id);
		this.uiStore = uiStore;
		this.id = id;
	}

	protected abstract getModel(id: IdType): Promise<ModelType>;

	@action
	private fetchModel = async (id: IdType) => {
		try {
			this.loading = true;
			this.model = await this.getModel(id);
			this.afterModelFetch(this.model);
		} catch (e) {
			this.error = Errors.handleError(e);
			this.afterModelFetchError();
		} finally {
			this.loading = false;
			this.afterAll();
		}
	};

	@action
	protected afterModelFetch(model: ModelType) {}

	@action
	protected afterModelFetchError() {}

	@action
	protected afterAll() {}
}
