// MARK: Mobx
import { action, runInAction, observable } from "mobx";

// MARK: Modules
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";

// MARK: Stores
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

export default class Store extends ModelStore<API.Contact> {
	protected uiStore: UIStore;

	protected getModel(id: string) {
		return API.getContact(id);
	}

	@action
	public openDeleteDialog = (onSuccess: () => void = () => {}) => {
		this.uiStore.showDialog(
			{
				title: Strings.contactsView.modalTitle,
				message: "" + this.model?.name,
			},
			async () => {
				try {
					await API.archiveContact(this.id, true);
					onSuccess();
				} catch (e) {
					this.uiStore.showSnackbar(e);
				}
			},
		);
	};

	@action
	public archive = async (onSuccess: () => void = () => {}) => {
		if (!this.model) {
			return;
		}

		try {
			this.loading = true;
			await API.archiveContact(this.id, !this.model.archivedAt);
			onSuccess();
		} catch (err) {
			runInAction(() => {
				this.error = Errors.handleError(err);
				this.uiStore.showSnackbar(this.error);
			});
		} finally {
			runInAction(() => this.loading = false);
		}
	}
}
