// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Components
import { MainLayout } from "../../layouts/MainLayout";

// MARK: Pages
import Home from "./Home";
import Contacts from "./Contacts";
import AdminUsers from "./AdminUsers";
import Clients from "./Clients";
import UserConfig from "./UserConfig/DetailsView";
import ClientProjects from "./ClientProjects";
import ProjectPhases from "./ProjectPhases";

// MARK: Hooks
import { useAuthRoute } from "../../hooks/useAuthRoute";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact={true} path="/dashboard" component={Home} />
				<Route path="/dashboard/contacts" component={Contacts} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/clients" component={Clients} />
				<Route path="/dashboard/user_config" component={UserConfig} />
				<Route path="/dashboard/clientProjects" component={ClientProjects} />
				<Route path="/dashboard/projectPhases" component={ProjectPhases} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
