import React from "react";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Libs
import { useHistory } from "react-router-dom";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: components
import SimpleTable from "../../../../components/SimpleTable";
import PageTableContainer from "../../../../components/PageTableContainer";
import {
	Container,
	IconButton,
	TableCell,
	TableRow,
	Button,
} from "@material-ui/core";
import {
	Visibility,
	Edit as EditIcon,
	Add as AddIcon,
	Delete as DeleteIcon,
} from "@material-ui/icons";

// MARK: Stores
import Store from "./store";
import { useGlobalStore } from "../../../context";
import { action } from "mobx";

const TableView = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<PageTableContainer>
			<SimpleTable
				title={Strings.clients.title}
				headers={Strings.clients.table.header}
				pagination={store.pagination}
				loading={store.loading}
				rows={store.items}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.phone}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>{item.document}</TableCell>
						<TableCell align={"center"}>
							<IconButton href={"/dashboard/clients/detail/" + item.id}>
								<Visibility />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton href={"/dashboard/clients/edit/" + item.id}>
								<EditIcon />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton
								onClick={action(() => {
									store.openDeleteDialog(item.id);
								})}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={(
					<Button color="secondary" href="/dashboard/clients/create">
						<AddIcon />
						{Strings.clients.table.createNewClient}
					</Button>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
