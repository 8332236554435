// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../modules/Strings";

// MARK: Layout
import { BaseLayout } from "../../layouts/BaseLayout";

// MARK: Components
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import Typography from "@material-ui/core/Typography";

// MARK: Stores
import { useGlobalStore } from "../context";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import { useHistory } from "react-router";

export const Login = observer(() => {
	const { authStore } = useGlobalStore();
	const history = useHistory();
	useAuthRoute(() => history.push("/dashboard"));

	return (
		<div className="login">
			{authStore.loading ? (
				<CircularProgress />
			) : (
				<form className="login" onSubmit={(e) => e.preventDefault()}>
					<div className="loginCard">
						<Typography className="loginTitle" variant="h5">
							{Strings.nav.title}
						</Typography>
						<TextField
							{...authStore.formController.field("email")}
							label={Strings.fields.email}
							disabled={authStore.loading}
							type="email"
							name="email"
						/>
						<TextField
							{...authStore.formController.field("password")}
							label={Strings.fields.password}
							disabled={authStore.loading}
							type="password"
							name="password"
						/>
						<Button
							type="submit"
							variant="outlined"
							className="loginButton"
							onClick={() =>
								authStore.login(() => history.push("/dashboard"))
							}
						>
							{Strings.login}
						</Button>
					</div>
				</form>
			)}
			<BaseLayout />
		</div>
	);
});
