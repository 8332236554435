// MARK: Stores
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import UIStore from "../../../../stores/UIStore";

// MARK: Modules
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { action } from "mobx";

// MARK: Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class Store extends PaginatedListStore<API.Client> {

	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(pageOffset: number) {
		return API.getAllClients(pageOffset);
	}

	@action
	private selectContact = async (id: string) => {
		const contact = this.items.find((i) => i.id === id)
			|| this.setLoading(true)
			|| await API.getContact(id).finally(() => this.setLoading(false));

		if (!contact) {
			throw {
				type: API.ErrorType.NotFound,
				message: Strings.error.clientNotFound(id),
			};
		}
		return contact;
	}

	@action
	private deleteContact = async (id: string) => {
		try {
			await API.deleteClient(id);
			this.uiStore.showSnackbar(Strings.clients.table.deleteClient);
			this.applyFilter((item) => item.id === id);
		} catch (e) {
			this.uiStore.showSnackbar(e.message);
		}
	}

	@action
	public openDeleteDialog = async (id: string) => {
		const contact = await this.selectContact(id);
		this.uiStore.showDialog(
			Strings.dialogs.deleteContact(contact.name),
			() => this.deleteContact(id),
		);
	};
}
