import React, { useCallback } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Store
import AdminTableStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useHistory } from "react-router-dom";
import { Button, IconButton, TableCell, TableRow} from "@material-ui/core";
import { Visibility, Edit, Delete, Add as AddIcon} from "@material-ui/icons";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new AdminTableStore(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`adminUsers/details/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push(`adminUsers/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`adminUsers/edit/${id}`),
		[history],
	);

	const handleDeleteAdmin = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.delete.message,
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteAdmin(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore.loading}
				title={Strings.admin.table.title}
				pagination={tableStore.pagination}
				rows={tableStore.items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.email,
					Strings.components.table.detailsAndEditAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
							<IconButton onClick={() => handleDeleteAdmin(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={(
					<Button
						startIcon={<AddIcon/>}
						onClick={pushToCreate}
					>
						{Strings.actions.create}
					</Button>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
