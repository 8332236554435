// MARK: Modules
import API from "../../../modules/API";
import ModelStore from "../../../stores/ModelStore";

// MARK: Store
import UIStore from "../../../stores/UIStore";

export default class Store extends ModelStore<API.HomeData, null> {

	constructor(uiStore: UIStore) {
		super(null, uiStore);
	}

	protected getModel() {
		return API.getHomeData();
	}
}
