import { action, computed, observable } from "mobx";
import Strings from "../../../../modules/Strings";
import * as Yup from "yup";
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

export interface IRequest {
	name: string;
	email: string;
	password: string;
	image: API.UncertainImage | null;
}

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;

	@observable public loading = false;
	@observable public error: string | null = null;

	public avatarController: FileStore;
	public formController = new FormStore({
		email: "",
		name: "",
		password: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		this.avatarController = new FileStore(uiStore, "image");
	}

	@action
	public createAdminUser = async () => {
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			this.loading = true;
			await API.createAdminUser({
				...this.formController.getValues(),
				image: this.avatarController.getUncertainfiedImage(),
			});

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.success);
		} catch (error) {
			this.error = Errors.handleError(error);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get validations(): Yup.ObjectSchema<object | undefined, object> {
		return Yup.object().shape({
			name: Yup.string().required("O nome é obrigatório"),
			email: Yup.string()
				.email("Informe um email valido")
				.required("O email é obrigatório"),
			password: Yup.string().required("A senha é obrigatória"),
		});
	}
}
