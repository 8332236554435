import { createStyles } from "@material-ui/core";

export const styles = createStyles({
	root: {
		width: "100%",
	},
	container: {
		maxHeight: 600,
	},

	footer: {
		paddingLeft: 10,
		paddingRight: 10,
	},
});
