import { action, observable } from "mobx";
import API from "../../../../modules/API";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";

interface IPushAble {
	push: (path: string) => void;
}

export default class Store {

	protected uiStore: UIStore;
	protected history: IPushAble;

	@observable public loading: boolean = false;
	@observable public error: string | null = null;

	constructor(uiStore: UIStore, history: IPushAble) {
		this.uiStore = uiStore;
		this.history = history;
	}

	public formController = new FormStore({
		password: "",
		name: "",
		phone: "",
		email: "",
		document: "",
	});

	@action
	public createClient = async (succesMessage: string) => {
		const data = this.formController.getValues();
		this.loading = true;
		try {
			await API.createClient({
				password: data.password,
				name: data.name,
				phone: data.phone,
				email: data.email,
				document: data.document,
			});
			this.uiStore.showSnackbar(succesMessage);
			this.history.push("/dashboard/clients");
		} catch (error) {
			this.error = error.message;
			this.uiStore.showSnackbar(error.message);
		} finally {
			this.loading = false;
		}
	};
}
