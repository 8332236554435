// MARK: mobx
import { action } from "mobx";

// MARK: Modules
import API from "../../../../modules/API";

// MARK: Store
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

export default class ViewStore extends ModelStore<API.ClientDetails> {
	protected uiStore: UIStore;

	constructor(id: string, uiStore: UIStore) {
		super(id, uiStore);
		this.uiStore = uiStore;
	}

	protected getModel(id: string) {
		return API.getClient(id);
	}

	protected afterModelFetch() {}
}
