// MARK: React
import * as React from "react";

import useStyles from "./styles";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Material
import { Button, CardContent, TextField } from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";

// MARK: Material

import { Select, FormControl, InputLabel } from "@material-ui/core";

// Mark: Router
import { useHistory } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";

const Create: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));
	const [clientId, setClientId] = React.useState("");
	const [phaseId, setPhaseId] = React.useState("");
	const classes = useStyles();

	const handleChangeClientId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClientId(event.target.value);
		store.setClientId(event.target.value);
	};

	const handleChangePhaseId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPhaseId(event.target.value);
		store.setClientId(event.target.value);
	};

	return (
		<div className="home">
			<CardForm
				title={Strings.clientProjects.create.title}
				loading={store.loading}
				actionsFooter={(
					<Button
						variant="contained"
						color="primary"
						component="span"
						onClick={() =>
							store.createClienteProject(
								clientId,
								phaseId,
							)
						}
					>
						{Strings.clientProjects.create.title}
					</Button>
				)}
			>
				<CardContent className={classes.fields}>
					<TextField
						{...store.formController.field("name")}
						label={Strings.clientProjects.create.fields.name}
					/>
					<TextField
						{...store.formController.field("description")}
						label={Strings.clientProjects.create.fields.discription}
					/>
					<FormControl>
						<InputLabel>Cliente</InputLabel>
						<Select
							native={true}
							value={clientId}
							onChange={handleChangeClientId}
						>
							<option aria-label="None" value="" aria-selected={true} />
							{store.model &&
								store.model.map((client) => (
									<option
										key={client.id}
										value={client.id}
										aria-selected={true}
									>
										{client.name}
									</option>
								))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Fase</InputLabel>
						<Select
							native={true}
							value={phaseId}
							onChange={handleChangePhaseId}
						>
							<option aria-label="None" value="" aria-selected={true} />
							{store.projectPhase &&
								store.projectPhase.map((phase) => (
									<option key={phase.id} value={phase.id} aria-selected={true}>
										{phase.name}
									</option>
								))}
						</Select>
					</FormControl>
					<FileCase title="Logo" {...store.logoImageService.toCase()} />
					<FileCase title="Anexos" {...store.filesService.toCase(true)} />
				</CardContent>
			</CardForm>
		</div>
	);
};

export default observer(Create);
