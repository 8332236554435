// MARK: React
import * as React from "react";

import useStyles from "./styles";

// MARK: Modulesd
import Strings from "../../../../modules/Strings";

// MARK: Material
import { Button, TextField, CardContent } from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";

// MARK: Material
import { Select, FormControl, InputLabel } from "@material-ui/core";

// Mark: Router
import { useHistory, useParams } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";

const Edit: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const { field } = store.formController;
	const classes = useStyles();

	const handleChangeClientId = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.setClientId(event.target.value);
	};

	const handleChangePhaseId = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.setPhaseId(event.target.value);
	};

	return (
		<div className="home">
			<CardForm
				title={Strings.clientProjects.edit.title}
				loading={store.loading}
				actionsFooter={
					// tslint:disable-next-line: jsx-wrap-multiline
					<Button
						variant="contained"
						component="span"
						onClick={() =>
							store.editClientProject(
								Strings.clientProjects.edit.editedProject,
								store.clientId,
								store.phaseId,
							)
						}
					>
						{Strings.clientProjects.edit.editButton}
					</Button>
				}
			>
				<CardContent className={classes.fields}>
					<TextField
						{...field("name")}
						label={Strings.clientProjects.edit.fields.name}
					/>
					<TextField
						{...field("description")}
						label={Strings.clientProjects.edit.fields.discription}
					/>

					<FormControl>
						<InputLabel shrink={true} id="label-client">
							{Strings.clientProjects.create.fields.client}
						</InputLabel>
						<Select
							native={true}
							value={store.clientId}
							onChange={handleChangeClientId}
						>
							{store.model &&
								store.model.map((client) => (
									<option
										key={client.id}
										value={client.id}
										aria-selected={true}
									>
										{client.name}
									</option>
								))}
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel shrink={true} id="label-phase">
							{Strings.clientProjects.create.fields.phase}
						</InputLabel>
						<Select
							native={true}
							value={store.phaseId}
							onChange={handleChangePhaseId}
						>
							<option aria-label="None" value="" aria-selected={true} />
							{store.projectPhase &&
								store.projectPhase.map((phase) => (
									<option key={phase.id} value={phase.id} aria-selected={true}>
										{phase.name}
									</option>
								))}
						</Select>
					</FormControl>

					<FileCase title="Logo" {...store.logoImageService.toCase()} />

					<FileCase title="Anexos" {...store.filesService.toCase(true)} />
				</CardContent>
			</CardForm>
		</div>
	);
};

export default observer(Edit);
