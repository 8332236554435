import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chips: {
			margin: theme.spacing(1),
		},
		archived: {
			color: green.A700,
			fontWeight: "bold",
		},
		unarchived: {
			color: red[700],
			fontWeight: "bold",
		},
	}),
);

export default useStyles;
