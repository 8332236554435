import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// Mark: Material
import {
	Card,
	CardContent,
	CardActions,
	Divider,
	Box,
	FormGroup,
	Typography,
	Grid,
	Switch,
	IconButton,
	Button,
} from "@material-ui/core";
import {
	WbSunnyOutlined as WbSunnyOutlinedIcon,
	WbSunny as WbSunnyIcon,
	Edit,
} from "@material-ui/icons";

// MARK: Components
import { DetailRow } from "../../../../components/DetailRow";
import { BackButtonHeader } from "../../../../components/BackButtonHeader";

// Mark: Store
import API from "../../../../modules/API";
import { useGlobalStore } from "../../../context";

const UserConfig: React.FC = () => {
	const { themeStore } = useGlobalStore();
	const history = useHistory();
	const [user, setUser] = React.useState<API.AdminUser>();
	const [checked, setChecked] = React.useState(
		themeStore.currentTheme.title === "darkTheme",
	);

	React.useEffect(() => {
		const fetch = async () => {
			const result = await API.getCurrentAdminUser();
			setUser(result);
		};
		fetch();
	}, []);

	const handleChange = React.useCallback(() => {
		themeStore.toggleTheme();
		setChecked((state) => !state);
	}, [themeStore]);

	const pushToEdit = React.useCallback(
		(id: string | undefined) =>
			history.push(`/dashboard/adminUsers/edit/${id}`),
		[history],
	);

	return (
		<Card className="home">
			<BackButtonHeader title={Strings.nav.configure} />
			<Box display="flex" flexDirection="column" width="100%">
				<CardContent>
					<DetailRow label={Strings.fields.name} value={user?.name} />
					<DetailRow label={Strings.fields.email} value={user?.email} />
				</CardContent>
			</Box>
			<Divider />
			<CardActions className="rowWidth">
				<FormGroup>
					<Typography component="div">
						<Grid
							component="label"
							container={true}
							alignItems="center"
							spacing={1}
						>
							<Grid item={true}>
								<WbSunnyOutlinedIcon />
							</Grid>
							<Grid item={true}>
								<Switch
									checked={checked}
									onChange={handleChange}
									name="checked"
								/>
							</Grid>
							<Grid item={true}>
								<WbSunnyIcon />
							</Grid>
						</Grid>
					</Typography>
				</FormGroup>
				<div style={{ flexGrow: 1 }} />
				<Button color="primary"  onClick={() => pushToEdit(user?.id)}>
					<Edit />
						{Strings.buttons.edit}
				</Button>
			</CardActions>
		</Card>
	);
};

export default observer(UserConfig);
