// MARK: React
import React from "react";
import ReactDOM from "react-dom";

// MARK: Libs
import firebase from "firebase/app";
import "firebase/messaging";

// MARK: APP
import { App } from "./pages";

// MARK: Resources
import Strings from "./modules/Strings";

// MARK: API
import { bootAPI } from "./modules/API";

if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker.register("/serviceworker.js");
	});
}

const firebaseConfig = {
	apiKey: "AIzaSyDmff-W-Z0n5g3peO3zi_qoWEk0Ik9BnHk",
	authDomain: "startapp-admin.firebaseapp.com",
	databaseURL: "https://startapp-admin.firebaseio.com",
	projectId: "startapp-admin",
	storageBucket: "startapp-admin.appspot.com",
	messagingSenderId: "42483431979",
	appId: "1:42483431979:web:c7dc0c37447f38ee34086e",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

messaging.getToken().then((currentToken) => {
	if (currentToken) {
		alert(currentToken);
	} else {
		alert("no messaging token");
	}
}).catch((err) => {
});

bootAPI(Strings);

ReactDOM.render(<App />, document.getElementById("app"));
