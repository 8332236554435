import React from "react";

// MARK: Pages
import { Auth } from "./Auth";
import { Login } from "./Login";
import { Dashboard } from "./Dashboard";
import { Recovery } from "./Recovery";

// MARK: Routing
import { Route, Switch, BrowserRouter } from "react-router-dom";

// MARK: GlobalState
import { GlobalContextProvider } from "./context";

// MARK: Components
import { CssBaseline } from "@material-ui/core";

export function App() {
	return (
		<GlobalContextProvider>
			<CssBaseline/>
			<BrowserRouter>
				<Switch>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route exact={true} path="/">
						<Auth />
					</Route>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/recovery/:token">
						<Recovery />
					</Route>
					<Route path="/recovery/">
						<Recovery />
					</Route>
				</Switch>
			</BrowserRouter>
		</GlobalContextProvider>
	);
}
